<template>
  <v-card class="mt-3 pt-7">
    <div>
      <h3 class="mx-5 mb-4">Adicione a uma CRC</h3>
      <select-crc v-model="formUserCrc.crcs" />
    </div>
    <v-divider></v-divider>
    <v-card-actions class="flex-column">
      <v-layout class="fill" row justify-space-between>
        <v-btn
          @click="$router.push({ name: 'users' })"
          color="#ffffff"
          :block="$isMobile"
          class="mt-1 cancel-button black-3--text"
          >Cancelar</v-btn
        >
        <v-btn
          @click="$emit('submit')"
          color="secondary-color-1"
          :block="$isMobile"
          class="mt-1 next-step-button white--text"
          >Confirmar e Salvar</v-btn
        >
      </v-layout>
      <v-layout class="mt-4">
        <p class="text-center black-4--text">
          Ao clicar em confirmar e enviar será enviado um e-mail para
          <strong>{{ formUser.email }}</strong> onde será possível continuar o
          cadastro
        </p>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>
<script>
  import SelectCrc from "@/modules/crc/views/components/SelectCRC";

  export default {
    props: {
      formUser: {
        type: Object,
        required: true
      },
      formUserCrc: {
        type: Object,
        required: true
      }
    },
    components: {
      SelectCrc
    }
  };
</script>
<style lang="scss" scoped></style>
