<template>
  <v-container class="content-page-half">
    <div class="d-flex justify-space-between align-center">
      <h2 class="white--text">Cadastrar Funcionários</h2>
      <h3 v-if="state.selectedRoleCanHaveCrc" class="white--text">
        Passo {{ state.currentStep }} de 2
      </h3>
    </div>

    <form-user-first-step
      v-if="state.currentStep === 1"
      :state="state"
      :formUser="formUser"
      @submit="firstStepSubmitHandler"
    />
    <form-user-second-step
      v-if="state.currentStep === 2"
      :formUser="formUser"
      :formUserCrc="formUserCrc"
      @submit="secondStepSubmitHandler"
    />
  </v-container>
</template>

<script>
  import FormUserFirstStep from "@/modules/account/user/views/components/FormUserFirstStep";
  import FormUserSecondStep from "@/modules/account/user/views/components/FormUserSecondStep";

  import { commonProfiles } from "@/modules/core/enums/profiles.enum";
  import { preparePhonesToSubmit } from "@/modules/core/helpers/phoneHelpers";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "FormUser",

    data: () => ({
      state: {
        currentStep: 1,
        selectedRoleCanHaveCrc: false
      },
      formUser: {
        firstName: "",
        surname: "",
        cpf: "",
        email: "",
        birthDate: "",
        userRole: {
          crcId: null,
          roleId: null
        },
        phones: [
          {
            areaCode: "",
            phone: "",
            phoneType: ""
          }
        ],
        document: {
          rg: null,
          issuingBody: null,
          cnh: null,
          categoryCnh: null
        }
      },
      formUserCrc: {
        crcs: []
      }
    }),

    components: {
      FormUserFirstStep,
      FormUserSecondStep
    },

    watch: {
      "formUser.userRole.roleId"(roleId) {
        if (commonProfiles.some(profile => profile.id === roleId)) {
          return (this.state.selectedRoleCanHaveCrc = true);
        }
        this.state.selectedRoleCanHaveCrc = false;
      }
    },

    computed: {
      ...mapGetters("user", {
        selectedCrc: "getSelectedUserCRC",
        userIsManagerOperation: "getUserIsManagerOperator"
      })
    },

    methods: {
      ...mapActions("user", ["createUser"]),
      ...mapActions(["toggleSnackbar"]),
      firstStepSubmitHandler() {
        if (!this.state.selectedRoleCanHaveCrc || this.userIsManagerOperation) {
          this.saveUser();
          return;
        }
        this.state.currentStep++;
      },
      secondStepSubmitHandler() {
        this.saveUser();
      },
      async saveUser() {
        try {
          const payload = this.mountUserPayload();

          const response = await this.createUser(payload);

          this.toggleSnackbar({
            text:
              response?.data?.message ?? "Funcionário cadastrado com sucesso",
            type: "success"
          });

          this.$router.push({ name: "users" });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },
      mountUserPayload() {
        const payload = {
          ...this.formUser,
          userRoles: this.mountUserRoles(),
          phones: preparePhonesToSubmit(this.formUser.phones)
        };

        delete payload.userRole;
        return payload;
      },
      mountUserRoles() {
        const role = {
          id: this.formUser.userRole.roleId
        };

        if (this.userIsManagerOperation) {
          return [
            {
              role,
              crc: {
                id: this.selectedCrc.id
              }
            }
          ];
        }
        if (this.formUserCrc.crcs.length && this.state.selectedRoleCanHaveCrc) {
          return this.formUserCrc.crcs.map(crcId => ({
            role,
            crc: {
              id: crcId
            }
          }));
        }
        return [{ role }];
      }
    }
  };
</script>

<style lang="scss" scoped></style>
