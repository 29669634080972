<template>
  <v-card class="mt-3 pt-7">
    <validation-observer>
      <v-form @submit.prevent="$emit('submit')" slot-scope="{ invalid }">
        <div class="px-5">
          <h3>Dados Pessoais</h3>
          <v-row>
            <v-col cols="12" sm="6">
              <field
                v-model="formUser.firstName"
                type="genericText"
                label="Nome"
                name="firstName"
                :isRequired="true"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <field
                v-model="formUser.surname"
                type="genericText"
                label="Sobrenome"
                name="surname"
                :isRequired="true"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <field
                v-model="formUser.email"
                type="email"
                label="E-mail"
                name="email"
                :isRequired="true"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="6" cols="12">
              <date-field
                v-model="formUser.birthDate"
                label="Data de nascimento"
                :isRequired="true"
                :max="new Date().toISOString().substr(0, 10)"
              />
            </v-col>
          </v-row>
          <h3>Documentos</h3>
          <v-row>
            <v-col cols="12" sm="6">
              <field
                v-model="formUser.cpf"
                type="cpf"
                label="CPF"
                name="cpf"
                :isRequired="true"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <field
                v-model="formUser.document.rg"
                type="rg"
                label="RG"
                name="rg"
                :isRequired="true"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <field
                v-model="formUser.document.issuingBody"
                type="genericText"
                label="Órgão expedidor"
                name="issuingBody"
                :isRequired="true"
              />
            </v-col>
          </v-row>

          <h3>Contato</h3>

          <phone-field v-model="formUser.phones" :isRequired="true" />

          <h3>Perfil de Funcionário</h3>
          <v-row>
            <v-col cols="12" sm="6">
              <field
                v-model="formUser.userRole.roleId"
                name="profile"
                type="select"
                label="Perfil"
                :items="profiles"
                item-text="description"
                item-value="id"
                :isRequired="true"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <field
                v-model="formUser.document.cnh"
                type="cnh"
                label="CNH"
                name="cnh"
                :isRequired="isRequiredSelectCnh || hasSelectedCategoryCnh"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <field
                v-model="formUser.document.categoryCnh"
                type="select"
                :items="cnhCategories"
                item-text="description"
                item-value="id"
                label="Categoria CNH"
                name="categoryCnh"
                :isRequired="isRequiredSelectCnh || hasSelectedCnh"
              />
            </v-col>
          </v-row>
        </div>

        <v-divider></v-divider>

        <v-card-actions class="d-flex flex-wrap justify-space-between">
          <v-btn
            @click="$router.push({ name: 'users' })"
            color="#FFF"
            :block="$isMobile"
            class="mt-1 cancel-button black-3--text"
            >Cancelar</v-btn
          >

          <v-btn
            type="submit"
            color="secondary-color-1"
            :disabled="invalid"
            :block="$isMobile"
            class="ma-0 mt-1 next-step-button white--text"
            >{{ confirmationLabel }}</v-btn
          >
        </v-card-actions>
      </v-form>
    </validation-observer>
  </v-card>
</template>
<script>
  import Field from "@/modules/core/views/components/Field";
  import DateField from "@/modules/core/views/components/DateField";
  import PhoneField from "@/modules/core/views/components/PhoneField";
  import { profiles, commonProfiles } from "@/modules/core/enums/profiles.enum";
  import { cnhCategories } from "@/modules/core/enums/cnhCategories.enum";

  import { ValidationObserver } from "vee-validate";
  import { mapGetters } from "vuex";

  export default {
    props: {
      formUser: {
        type: Object,
        required: true
      },
      state: {
        type: Object,
        required: true
      }
    },
    components: {
      Field,
      DateField,
      PhoneField,
      ValidationObserver
    },
    data: () => ({
      isRequiredSelectCnh: false,

      profiles: [],
      cnhCategories: [{ description: "Nenhum", id: "" }]
    }),
    mounted() {
      this.profiles = Object.values(this.profileSelect());
      this.cnhCategories = [
        ...this.cnhCategories,
        ...this.mountCnhCategories()
      ];
    },
    watch: {
      "formUser.userRole.roleId"(roleId) {
        this.isRequiredSelectCnh = this.canSelectCnh(roleId);
      }
    },
    computed: {
      ...mapGetters("user", {
        userIsAdmin: "getUserIsAdmin"
      }),
      hasSelectedCategoryCnh() {
        return !!this.formUser.document.categoryCnh;
      },
      hasSelectedCnh() {
        return !!this.formUser.document.cnh;
      },
      confirmationLabel() {
        return this.state.selectedRoleCanHaveCrc ? "Próximo" : "Salvar";
      }
    },
    methods: {
      canSelectCnh(roleId) {
        return roleId === profiles.DRIVER.id;
      },
      profileSelect() {
        if (this.userIsAdmin) {
          return { ...profiles };
        }

        return { ...commonProfiles };
      },
      mountCnhCategories() {
        return Object.keys(cnhCategories).map(cnhCategory => ({
          description: cnhCategory,
          value: cnhCategory
        }));
      }
    }
  };
</script>
<style lang="scss" scoped></style>
